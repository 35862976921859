<template>
    <div class="qc-info">
        <div class="line15"></div>
        <div class="qc-content">
            <div class="qc-content__title">{{ infoList.name }}</div>
            <img id="img" :src="this.url">
            <div id="signature"></div>
            <div v-for="(item, index) in infoList.child" :key="index">
                <div v-if="item.taskType == 'pic'">
                    <div class="card-upload">
                        <van-uploader v-model="fileList"
                                      accept="image"
                                      :max-count="5"
                                      :after-read="afterRead"
                                      :before-delete="deletePic"/>
                    </div>
                    <div v-if="item.taskCode == 'accountPic'">
                        <div class="qc-left">最新日期对应页拍照</div>
                    </div>
                    <div v-if="item.taskCode == 'checkPic'">
                        <div class="qc-left">室内室外各一张水印照片及站长合影(至少3张)</div>
                    </div>
                </div>
                <div v-else-if="item.taskType == 'input_text'">
                    <van-field class="workNote"
                               v-model="item.taskValue"
                               rows="3"
                               autosize
                               type="textarea"
                               placeholder="填写备注信息（5-50个字）"
                               maxlength="50"/>
                </div>
                <div v-else-if="item.taskType == 'invertRedio'" class="qc-content__item  flex">
                    <div class="qc-left">{{ item.taskName }}</div>
                    <div class="qc-right">
                        <van-radio-group v-model="item.taskValue" direction="horizontal"
                                         @change="itemClick(infoList.child, index)">
                            <van-radio name="0">
                                规范
                                <template #icon="props">
                                    <img class="img-icon" :src="props.checked ? qc_no : qc_default"/>
                                </template>
                            </van-radio>
                            <van-radio name="1">
                                不规范
                                <template #icon="props">
                                    <img class="img-icon" :src="props.checked ? qc_yes : qc_default"/>
                                </template>
                            </van-radio>

                        </van-radio-group>
                    </div>
                </div>
                <div>
                    <van-field v-if="item.taskValue == '1'"
                               v-model="item.question"
                               label="问题描述"
                               class="state-info"
                               placeholder="请输入具体问题"
                               maxlength="200"
                    />
                </div>
            </div>

        </div>


        <div class="nextBtn">
            <van-button class="publicBtnActive"
                        slot="button"
                        type="info"
                        color="#3F7C53"
                        v-if="this.$route.query.detail != '1' &&this.infoList.name != '站长签名'"
                        @click="submitClick">确认
            </van-button>
            <van-button type="info" color="#3F7C53" @click="save" id="sign_ok"
                        v-if="this.$route.query.detail != '1' &&this.infoList.name == '站长签名'&&this.url==''">保存签名
            </van-button>
            <van-button class="clear-btn"
                        v-if="this.$route.query.detail != '1' && this.infoList.name == '站长签名'&&this.url==''"
                        @click="clear">重写
            </van-button>
        </div>
    </div>
</template>

<script>
    import HeaderSiteInfo from '../../components/siteInfo/headerSiteInfo'
    import common from "@/utils/common";
    import {saveFileInfo} from '@/utils/api'
    import JsZip from "jszip";
    import {Button, Toast} from 'vant';
    import {uwStatisticAction} from "../../utils/util";
    import {
        getMyTask,
        saveMyTask,
        uploadZipFile,
        bindEquipment,
        getSiteByStationNo,
        uploadZipAndAlreadyImg,
        submitJoin,
        getSubTaskDetail
    } from '@/utils/api'

    export default {
        components: {
            HeaderSiteInfo,
            [Button.name]: Button,
            [Toast.name]: Toast
        },
        data() {
            return {
                qcInfo: {},
                fileList: [],
                workRemark: null,
                qc_default: require('@/assets/img/qc/qc_default.png'),
                qc_yes: require('@/assets/img/qc/qc_yes.png'),
                qc_no: require('@/assets/img/qc/qc_no.png'),
                logoImg: require('@/assets/img/siteManage/headerIcon.png'),
                siteInfo: {},
                taskInfo: {},
                infoList: [],
                taskContent: {
                    stationManager: {},
                    manageRegulation: {},
                    superviseCooperate: {},
                    inspectPic: {child: [{taskName: ''}]},
                    remark: {child: [{taskName: ''}]}
                },
                imgArr: [],
                uploadZipImgList: [],
                stationNo: null,
                uploadImgArr: [],
                showStep: '',
                addPicList: [],
                signFilePath: '',
                imgsrc: '',
                tempToken: '',
                url: ''
            }
        },
        mounted() {
            this.togetSubTaskDetail();
            uwStatisticAction('/qc/submitqcInfo', '站点巡检子任务内容输入');
        },
        methods: {
            clear() {
                $("#signature").jSignature("reset");
            },
            callback(base64data) {
                this.tosaveFileInfo(base64data)
            },
            tosaveFileInfo(base64data) {
                saveFileInfo({
                    fileType: 'qcSign',
                    stationNo: this.$route.query.stationNo,
                    token: this.tempToken,
                    picOwnNo: this.$route.query.taskNo, // 传任务号
                    fileContent: base64data,
                }).then(res => {
                    if (res.code == 200) {
                        if (res.data && res.data.businessCode == 200) {
                            common.setItem('signZZ', true)
                            console.log("res:", res)
                            this.signFilePath = res.data.filePath;
                            this.toSaveMyTask()
                        }
                    }
                })
            },
            save() {
                var datapair = $("#signature").jSignature("getData", "image")
                var array = datapair.splice(",")
                if (array.length == 0) {
                    this.$toast('请站长签名')
                    return
                }
                // data:image/png;base64,
                console.log("data:" + array[0] + "," + array[1])
                let imgSrc = "data:" + array[0] + "," + array[1]
                let _this = this
                common.rotateBase64Img(imgSrc, 90, (base64) => {
                    _this.callback(base64)
                })
            },
            togetSubTaskDetail() {
                getSubTaskDetail({
                    'taskNo': this.$route.query.taskNo,
                    'subTaskCode': this.$route.query.code
                }).then(res => {
                    if (res.code == 200) {
                        if (res.data && res.data.businessCode == 200) {
                            this.infoList = res.data.data
                            console.log('this.infoList.name:', this.infoList.name);
                            if (this.infoList.name == '站长签名') {
                                $(document).ready(function () {
                                    let params = {
                                        width: '6.5rem',
                                        height: document.body.clientHeight + 'px',
                                        lineWidth: '3'
                                    };
                                    $("#signature").jSignature(params)
                                })
                                if (this.infoList.child[0].taskValue != '') {
                                    var baseUrl = process.env.NODE_ENV != 'production' ? 'http://124.70.167.116:20081' + '/file/' : window.location.origin + '/file/'
                                    var tokenUrl = '?Authorization=' + common.getItem('wtToken');
                                    this.url = baseUrl + this.infoList.child[0].taskValue + tokenUrl;
                                    console.log("url:" + this.url)
                                }

                            }
                            console.log('this.infoList:', this.infoList);
                            if (this.infoList.child[0].taskType == 'pic') {
                                var baseUrl = process.env.NODE_ENV != 'production' ? 'http://124.70.167.116:20081' + '/file/' : window.location.origin + '/file/'
                                var tokenUrl = '?Authorization=' + common.getItem('wtToken')

                                for (let obj of this.infoList.child[0].taskValue) {
                                    this.fileList.push({
                                        'url': baseUrl + obj + tokenUrl
                                    })
                                }

                            }

                            if (this.infoList.child[0].taskType == 'invertRedio') {
                                for (let obj of this.infoList.child) {
                                    if (obj.taskValue) {
                                        obj.taskValue = obj.taskValue.toString()
                                    }

                                }
                            }
                            console.log(this.fileList)
                        }
                    }
                })
            },
            itemClick(item, index) {
                item[index].hasFinished = true
            },
            submitClick() {
                if (this.infoList.child[0].taskType == 'pic' && this.uploadZipImgList.length != 0) {
                    let self = this
                    let imgName = this.infoList.child[0].taskCode
                    var zip = new JsZip();
                    var img = zip.folder("images");
                    for (var i = 0; i < this.uploadZipImgList.length; i++) {
                        // console.log("mingzi:", this.uploadZipImgList[i].name)
                        img.file(imgName + '_' + i + this.uploadZipImgList[i].name, this.uploadZipImgList[i].data.substring(this.uploadZipImgList[i].data.indexOf(",") + 1), {base64: true});
                    }
                    let json = {imgName: self.fileImgUrls}
                    let fileAleradyJson = JSON.stringify(json)
                    zip.generateAsync({type: "blob"}).then(function (content) {
                        console.log("上传图片到服务器")
                        uploadZipAndAlreadyImg(content, self.stationNo, fileAleradyJson).then(res => {
                            if (res.code == 200) {
                                if (res.data.businessCode && res.data.businessCode === 200) {
                                    self.addPicList = res.data[imgName]
                                    self.toSaveMyTask()
                                }
                            }
                        }).catch(() => {
                        })

                    }, e => {
                        this.$toast('上传图片异常')
                        console.log('error', e)
                    });

                } else {
                    this.toSaveMyTask()
                }
            },
            toSaveMyTask() {
                if (this.infoList.child[0].taskType == 'input_text') {
                    if (this.infoList.child[0].taskValue && (this.infoList.child[0].taskValue.length < 5 || this.infoList.child[0].taskValue.length > 50)) {
                        this.$toast('请输入5-50字的备注信息')
                        return
                    }
                } else if (this.infoList.child[0].taskType == 'pic') {
                    if (this.infoList.child[0].taskValue.length > 0) {
                        this.infoList.child[0].taskValue = this.infoList.child[0].taskValue.concat(this.addPicList)
                    } else {
                        this.infoList.child[0].taskValue = this.addPicList
                    }

                    if (this.infoList.child[0].taskValue.length >= this.infoList.child[0].minInputCount) {
                        this.infoList.child[0].hasFinished = true
                    } else {
                        this.infoList.child[0].hasFinished = false
                        this.$toast('请上传至少' + this.infoList.child[0].minInputCount + '张图片')
                        return
                    }
                } else if (this.infoList.child[0].taskType == 'sign') {
                    this.infoList.child[0].taskValue = this.signFilePath;
                    this.infoList.child[0].hasFinished = true
                } else if (this.infoList.child[0].taskType == 'invertRedio') {
                    for (let obj of this.infoList.child) {
                        if (!obj.taskValue) {
                            this.$toast('请选择是否规范!')
                            return
                        }
                        if (obj.taskValue == '1' && !obj.question) {
                            this.$toast('不规范，需要填写具体问题！')
                            return
                        }
                    }
                }

                saveMyTask({
                    'taskNo': this.$route.query.taskNo,
                    'currStep': this.$route.query.code,
                    'stepContent': JSON.stringify(this.infoList),
                    'lat': this.$route.query.lat,
                    'lng': this.$route.query.lng,
                    'hasSubmit': false
                }).then(res => {
                    if (res.code == 200) {
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            this.$router.push({
                                name: 'qcInfoList',
                                query: {taskNo: this.$route.query.taskNo, stationNo: this.$route.query.stationNo}
                            })
                        }
                    }
                })
            },
            afterRead(file, detail) {
                console.log('上传图片：', file)
                // 大于15M
                if (file.file.size > 15 * 1024 * 1024) {
                    Toast('图片太大，请上传小于15M的图片')
                    return
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList.splice(detail.index, 1)
                    Toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 100 * 1024) {
                    console.log('小图片')
                    let namearr = file.file.name.split('.')
                    console.log(namearr)
                    let name = '.' + namearr[namearr.length - 1]
                    console.log(name)
                    _this.uploadZipImgList.push({data: file.content, name: name})
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)

                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {
                        console.log('压缩图片')
                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList.push({data: dataURL, name: name})
                            // console.log(dataURL)
                        })
                    }
                }
            },
            deletePic(file, pic) {
                console.log('aaaa:删除图片:', pic.index)
                console.log(this.infoList.child[0].taskValue.length)
                console.log(this.uploadZipImgList)
                if (this.infoList.child[0].taskValue.length > pic.index) {
                    this.infoList.child[0].taskValue.splice(pic.index, 1)
                } else {
                    let i = pic.index - this.infoList.child[0].taskValue.length
                    this.uploadZipImgList.splice(i, 1)
                }
                this.fileList.splice(pic.index, 1)
            },
        },
    }
</script>

<style lang="less">
    .qc-info .van-uploader__preview {
        margin-right: 0.2rem !important;
    }
</style>

<style lang="less" scoped>
    .qc-info {
        background: #f7f8f9;
        padding-bottom: 2.5rem;
        height: 100%;
        overflow: auto;

        .desc {
            padding: 0.3rem;
            color: #999;
            font-size: 0.2rem;
            // background: #f7f8f9;
            letter-spacing: 0.02rem;
        }

        .flex {
            display: flex;
        }

        .line15 {
            height: 0.1rem;
        }

        .van-radio--horizontal {
            margin-right: 0.26rem;

            &:nth-child(2) {
                margin-right: 0;
            }
        }

        .van-radio__label {
            margin-left: 0.08rem !important;
        }

        .img-icon {
            width: 0.28rem;
            height: 0.28rem;


            &:nth-child(1) {
                margin-left: 0;
            }
        }

        .qc-content {
            background: #fff;
            margin-bottom: auto;
        }

        .qc-content__title {
            text-align: left;
            background: #ffffff;
            height: 0.8rem;
            line-height: 0.8rem;
            padding: 0 0.3rem;
            color: #333333;
            font-weight: bold;
            font-size: 15px;
            margin-top: 0.1rem;

            &:before {
                width: 8px;
                height: 5px;
                color: #3f7c53;
                content: "| ";
            }
        }

        .qc-content__item {
            justify-content: space-between;
            padding: 0.2rem 0.3rem;
            align-items: center;
            border-bottom: 0.01rem solid #eee;

            .qc-left {
                width: 5.8rem;
                font-size: 0.2rem;
            }

            .qc-right {
                width: 2.8rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 0.2rem;
            }
        }

        .state-info {
            font-size: 0.2rem;
            font-weight: 200;
        }

        .site-item {
            padding: 0.2rem 0.2rem;

            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background: #fff;

            .site-item-cell {
                margin: 0.2rem 0;
                padding: 0.2rem;
            }

            .VDS-ShadowBg_home {
                border-radius: 8px;
                box-shadow: 0px 2.5px 5px 5px rgba(0, 0, 0, 0.04);
            }

            .logo-img {
                width: 68px;
                height: 68px;
            }

            .site-item_right {
                margin-left: 0.2rem;
                flex-direction: column;
                justify-content: space-between;

                .site-item_one {
                    font-size: 15px;
                    color: #333333;
                    font-weight: bold;
                }

                .site-item_two {
                    // margin-top: -0.1rem;
                    font-weight: 13px;
                    color: #999999;
                    margin: 3px 0;
                }

                .site-item_three {
                    div {
                        border: 0.01rem solid #3f7c53;
                        padding: 0 8px;
                        margin-right: 0.1rem;
                        font-size: 12px;
                        color: #3f7c53;
                        border-radius: 4px;
                    }
                }
            }
        }

        .card-upload {
            // margin: 0 0.3rem;
            height: 1.6rem;
            background: #fff;
            display: flex;
            align-items: center;
            padding: 0 0.2rem;
            overflow: hidden;
        }

        .nextBtn {
            width: 100%;
            padding: 12px;
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .signature {
        height: 100%;
        background: #f5f5f5;
        flex-direction: column;

        .flex {
            display: flex;
        }

        .desc {
            flex: 1;
            height: 100%;
            text-align: center;

            span {
                display: inline-block;
                transform: rotate(-90deg);
            }
        }

        .content {
            height: 80%;
        }

        .sign_show {
            width: 2rem;
            height: 1rem;
            border: 0.01rem solid #666;
        }

        .footer {
            flex-direction: column;

            .clear-btn {
                background: #999;
                color: #fff;
            }

            .van-button {
                width: 100%;
                flex: 1;

                .van-button__text {
                    transform: rotate(-90deg) translateY(-0.35rem);
                    display: inline-block;
                }
            }
        }
    }

    .clear-btn {
        background: #999;
        color: #fff;
    }
</style>
